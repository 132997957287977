export const availableRoles = {
    Doer : ['Doer'],
    Scout: ['Doer', 'Scout'],
    Merchant: ['Doer', 'Scout', 'Merchant'],
    Knight: ['Doer', 'Scout', 'Knight'],
    Baron: ['Doer', 'Scout', 'Knight', 'Merchant', 'Baron'],
    King: ['Doer', 'Scout', 'Knight', 'Merchant', 'Baron', 'King', 'Admin'],
    Admin: ['Doer', 'Scout', 'Knight', 'Merchant', 'Baron', 'King', 'Admin'],
  }

